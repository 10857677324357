import React, { FC, useRef } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import ReactToPrint from 'react-to-print';
import './print.css'; // Import the CSS file
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/dark-logo.svg';
import { ReactComponent as LogoLight } from 'src/assets/images/logos/light-logo.svg';

type InvoiceData = {
  id: string;
  invoice_number: string;
  po_number: string;
  reference_number: string;
  purchase_date: string;
  supplier_code: string;
  supplier_name: string;
  supplier_address: string;
  supplier_contact_person: string;
  transactions: {
    product_name: string;
    description: string;
    unit: string;
    cost: number;
    quantity: number;
    amount: number;
    model: any;
    sku: any;
    serial_numbers: any;
  }[];
  created_by_userid: string;
  created_by_name: string;
  subtotal: number;
};

interface GridItemProps {
  data: InvoiceData;
}

const GridItem: FC<GridItemProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();

  // const theme = useTheme();
  const customizer = useSelector((state: AppState) => state.customizer);
  console.log('Transaction Data:', data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 4,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}

            {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
          </Box>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            RRF Building
            <br />
            San Miguel Calasiao, Pangasinan
            <br />
            Tel No: +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            PURCHASE ORDER NO:{data.po_number}
          </Typography>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            INVOICE NO:{data.invoice_number}
          </Typography>

          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            REFERENCE NO: {data.reference_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Purchase Date: {dayjs(data.purchase_date).format('MMMM DD, YYYY')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            Supplier:
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {data.supplier_name}
            <br />
            Supplier Code: {data.supplier_code}
            <br />
            Address: {data.supplier_address}
            <br />
            Contact Person: {data.supplier_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left', mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>DESCRIPTION</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>MODEL</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>SERIAL NO.</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
              <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}>
                {transaction.description}
              </TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.model}</TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.serial_numbers}</TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.unit}</TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Processor: {data.created_by_name}
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'right', color: 'text.primary', fontWeight: 'bold' }}
      >
        Subtotal: {formatWithPrefix(data.subtotal)}
      </Typography>
    </Box>
  );
};

const Invoice: FC<{ data: InvoiceData }> = ({ data }) => {
  const componentRef = useRef(null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={9} ref={componentRef}>
          <GridItem data={data} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button variant="contained" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
              Send Transaction Record
            </Button>
            <Button variant="outlined" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
              Download
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button variant="outlined" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable {
                    font-size: 7px;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invoice;
