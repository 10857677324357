import React, { FC, ReactNode, useState, useEffect } from 'react';
import {
  Alert,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
  ConfigProvider,
  Form,
  Button,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { TableProps, theme } from 'antd/lib';
import {
  Box,
  Grid,
  Typography as TypographyMUI,
  Table as TableMUI,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useTheme as useMuiTheme,
  Paper,
  Button as ButtonMUI,
} from '@mui/material';
import DashboardCard from 'src/components/shared/DashboardCard';
import axiosClient from 'src/axios_client';
import { DataType } from './types';
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { GridItem } from './GridItem';
import { useSelector, useDispatch, AppState } from 'src/store/Store';
import { IconFile3d, IconTrash } from '@tabler/icons';

const { confirm } = Modal;

const data: DataType[] = [
  // {
  //   key: '1',
  //   name: 'HISENSE 32" TV',
  //   quantity: 1,
  //   serial_number: 'X43532',
  //   brand: 'HISENSE',
  //   model: 'X001',
  //   status: 0,
  //   reference: 'IET-00001',
  // },
];

interface TransmittalFormProps {
  onTransactionPosted: () => void;
}

interface SupplyCenter {
  id: number;
  name: string;
  api: string | null;
  is_disabled: boolean | null;
}

interface ApiResponse {
  data: SupplyCenter[];
  // Add other fields from the API response if needed
}

const TransmittalForm: React.FC<TransmittalFormProps> = ({ onTransactionPosted }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [tableData, setTableData] = useState<DataType[]>(data);
  const [productDetails, setProductDetails] = useState<Partial<DataType>>({
    quantity: 1,
  });
  const [pickupBy, setPickupBy] = useState<string>('');
  const [destination, setDestination] = useState<number>(1);
  const [notes, setNotes] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleChange = (value: number) => {
    setProductDetails({ ...productDetails, status: value });
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Serial No.',
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Model No.',
      key: 'model',
      dataIndex: 'model',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: number) => (
        <Tag color={status === 0 ? 'orange' : 'geekblue'} key={status}>
          {status === 0 ? 'DAMAGED' : 'GOOD'}
        </Tag>
      ),
    },
    {
      title: 'Reference',
      key: 'reference',
      dataIndex: 'reference',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Typography.Link onClick={() => handleEdit(record)}>Edit</Typography.Link>
          <Divider type="vertical" />
          <Typography.Link onClick={() => handleDelete(record.key)}>Delete</Typography.Link>
        </span>
      ),
    },
  ];

  const showConfirmation = (
    action: () => void,
    title: string,
    content: ReactNode,
    width: number,
  ) => {
    confirm({
      title,
      icon: <ExclamationCircleFilled />,
      content,
      onOk() {
        action();
      },
      width,
    });
  };

  const addProduct = () => {
    if (productDetails.name && (productDetails.status === 0 || productDetails.status === 1)) {
      const newProduct: DataType = {
        key: isEditing ? productDetails.key! : Date.now().toString(),
        name: productDetails.name,
        quantity: productDetails.quantity ?? 1,
        serial_number: (productDetails.quantity ?? 1) > 1 ? '' : productDetails.serial_number ?? '',
        brand: productDetails.brand ?? '',
        model: productDetails.model ?? '',
        status: productDetails.status,
        reference: productDetails.reference ?? '',
      };

      if (isEditing) {
        // Check for duplicates when editing
        const duplicateIndex = tableData.findIndex(
          (item) =>
            item.key !== newProduct.key &&
            item.name === newProduct.name &&
            item.brand === newProduct.brand &&
            item.model === newProduct.model &&
            item.reference === newProduct.reference &&
            item.serial_number === newProduct.serial_number,
        );

        if (duplicateIndex !== -1) {
          messageApi.open({
            type: 'error',
            content: `A product with the same details already exists.`,
            className: 'robotoFont',
            style: {
              marginTop: '20vh',
            },
          });
          return;
        }

        // Update existing product
        const updatedData = tableData.map((item) =>
          item.key === newProduct.key ? newProduct : item,
        );
        setTableData(updatedData);
      } else {
        // Adding a new product
        const existingProductIndex = tableData.findIndex(
          (item) =>
            item.name === newProduct.name &&
            item.brand === newProduct.brand &&
            item.model === newProduct.model &&
            item.reference === newProduct.reference,
        );

        if (existingProductIndex !== -1) {
          const existingProduct = tableData[existingProductIndex];

          if (
            newProduct.serial_number &&
            existingProduct.serial_number === newProduct.serial_number
          ) {
            messageApi.open({
              type: 'error',
              content: `This product with the same serial number already exists.`,
              className: 'robotoFont',
              style: {
                marginTop: '20vh',
              },
            });
            return;
          }

          if (
            newProduct.serial_number &&
            existingProduct.serial_number !== newProduct.serial_number
          ) {
            setTableData([...tableData, newProduct]);
          } else {
            const updatedData = tableData.map((item, index) =>
              index === existingProductIndex
                ? {
                    ...item,
                    quantity: (item.quantity ?? 0) + (newProduct.quantity ?? 1),
                  }
                : item,
            );
            setTableData(updatedData);
          }
        } else {
          setTableData([...tableData, newProduct]);
        }
      }

      setProductDetails({});
      setIsEditing(false);
    } else {
      messageApi.open({
        type: 'error',
        content: `Product name and status are required.`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const handleEdit = (record: DataType) => {
    setProductDetails({ ...record, serial_number: record.serial_number });
    setIsEditing(true);
  };

  const handleDelete = (key: string) => {
    setTableData(tableData.filter((item) => item.key !== key));
  };

  const clearTransaction = () => {
    setTableData([]);
    setProductDetails({});
    setIsEditing(false);
    setNotes('');
    setPickupBy('');
  };

  const postTransaction = () => {
    if (tableData.length === 0) {
      messageApi.open({
        type: 'error',
        content: 'Cannot post an empty transaction. Please add products to the table.',
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      return;
    }

    if (!pickupBy.trim()) {
      messageApi.open({
        type: 'error',
        content: 'Please enter a value for "Pickup By".',
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      return;
    }

    const content = (
      <GridItem
        data={{
          products: tableData,
          pickup_by: pickupBy,
          destination: destination,
          notes: notes,
        }}
      />
    );

    showConfirmation(handleConfirmPost, 'Confirm Transaction', content, 800);
  };

  const handleConfirmPost = async () => {
    try {
      const response = await axiosClient.post('/te_transactions', {
        products: tableData,
        pickup_by: pickupBy,
        destination: destination,
        notes: notes,
      });

      console.log('Transaction posted successfully:', response.data);
      messageApi.open({
        type: 'success',
        content: 'Transaction posted successfully.',
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      clearTransaction();
      onTransactionPosted();
    } catch (error) {
      console.error('Error posting transaction:', error);
      messageApi.open({
        type: 'error',
        content: `Error posting transaction: ${error}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const muiTheme = useMuiTheme();

  const [supplyCenterOptions, setSupplyCenterOptions] = useState<
    { value: number; label: string }[]
  >([]);

  useEffect(() => {
    const fetchSupplyCenters = async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/supply_centers`);
        fetchURL.searchParams.set('page', '1');
        fetchURL.searchParams.set('per_page', '10'); // Adjust as needed
        fetchURL.searchParams.set('filters', JSON.stringify([]));
        fetchURL.searchParams.set('sorting', JSON.stringify([]));

        const response = await axiosClient.get<ApiResponse>(fetchURL.href);
        const json = response.data;

        setSupplyCenterOptions(
          json.data.map((center: SupplyCenter) => ({
            value: center.id,
            label: center.name,
          })),
        );
      } catch (error) {
        console.error('Error fetching supply centers:', error);
      }
    };

    fetchSupplyCenters();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      {contextHolder}
      <Form layout="vertical">
        <Row gutter={16}>
          <Col sm={24} md={12} lg={8}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                border: 'none',
                backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
                color: isDarkMode ? '#ffffff' : '#000000',
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <h4
                    style={{
                      borderBottom: '1px solid #e8e8e8',
                      paddingBottom: '8px',
                      marginBottom: '16px',
                      fontWeight: 'normal',
                      fontSize: '18px',
                      color: isDarkMode ? '#ffffff' : '#606060',
                    }}
                  >
                    Entry Product
                  </h4>
                </Col>
                <Col span={24}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    <Tooltip placement="right" title="Search or Add the Product here">
                      Product
                      <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </Typography.Text>
                  <Input
                    value={productDetails.name || ''}
                    onChange={(e) => setProductDetails({ ...productDetails, name: e.target.value })}
                  />
                </Col>

                <Col className="gutter-row" span={12}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Quantity
                  </Typography.Text>
                  <Input
                    type="number"
                    min={1}
                    value={productDetails.quantity || 1}
                    onChange={(e) => {
                      const newQuantity = Math.max(1, parseInt(e.target.value) || 1);
                      setProductDetails({
                        ...productDetails,
                        quantity: newQuantity,
                        serial_number: newQuantity > 1 ? '' : productDetails.serial_number,
                      });
                    }}
                  />
                </Col>
                <Col className="gutter-row" span={12}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Serial Number
                  </Typography.Text>
                  <Input
                    value={productDetails.serial_number ?? ''}
                    onChange={(e) =>
                      setProductDetails({ ...productDetails, serial_number: e.target.value })
                    }
                    disabled={(productDetails.quantity ?? 1) > 1}
                    placeholder={
                      (productDetails.quantity ?? 1) > 1
                        ? 'Multiple items - No serial number'
                        : 'Enter serial number'
                    }
                  />
                </Col>
                <Col className="gutter-row" span={12}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Brand
                  </Typography.Text>
                  <Input
                    value={productDetails.brand || ''}
                    onChange={(e) =>
                      setProductDetails({ ...productDetails, brand: e.target.value })
                    }
                  />
                </Col>
                <Col className="gutter-row" span={12}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Model
                  </Typography.Text>
                  <Input
                    value={productDetails.model || ''}
                    onChange={(e) =>
                      setProductDetails({ ...productDetails, model: e.target.value })
                    }
                  />
                </Col>
                <Col span={12}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Status
                  </Typography.Text>
                  <Select
                    value={productDetails.status ?? 0}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    options={[
                      {
                        value: 1,
                        label: 'Good',
                      },
                      {
                        value: 0,
                        label: 'Damaged',
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    <Tooltip placement="right" title="Reference No. (IPO/Issuance #/etc)">
                      Reference Number
                      <ExclamationCircleFilled style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </Typography.Text>
                  <Input
                    value={productDetails.reference || ''}
                    onChange={(e) =>
                      setProductDetails({ ...productDetails, reference: e.target.value })
                    }
                  />
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    <Tooltip placement="right" title="Scan Employee ID">
                      Pickup By
                      <ExclamationCircleFilled style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </Typography.Text>
                  <Input value={pickupBy} onChange={(e) => setPickupBy(e.target.value)} />
                </Col>
                <Col span={24}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Destination
                  </Typography.Text>
                  <Select
                    value={destination}
                    style={{ width: '100%' }}
                    onChange={(value: number) => setDestination(value)}
                    options={supplyCenterOptions}
                    placeholder="Select a supply center"
                  />
                </Col>
                <Col span={24}>
                  <Typography.Text
                    style={{ color: isDarkMode ? '#ffffff' : '#606060', fontSize: '13px' }}
                  >
                    Note
                  </Typography.Text>
                  <Input value={notes} onChange={(e) => setNotes(e.target.value)} />
                </Col>
                <Col span={24}>
                  <Row justify="end" style={{ marginTop: '20px' }}>
                    <Col>
                      <Button
                        type="primary"
                        className="robotoFont"
                        icon={isEditing ? <EditOutlined /> : <PlusOutlined />}
                        onClick={addProduct}
                      >
                        {isEditing ? 'Save Changes' : 'Add Product'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Paper>
          </Col>

          <Col sm={24} md={12} lg={16}>
            <Paper
              elevation={3}
              sx={{
                boxShadow: 'none',
                border: 'none',
                marginTop: '-75px',
                backgroundColor: 'transparent',
              }}
            >
              <Row gutter={16} align="middle">
                <Col flex="1">
                  <h4
                    style={{
                      fontWeight: '600',
                      fontSize: '18px',
                      color: '#ffffff',
                    }}
                  >
                    Transmittal Details
                  </h4>
                </Col>
                <Col>
                  <ButtonMUI
                    style={{ marginRight: '-10px' }} // Adjust margin as needed
                    startIcon={<IconTrash />}
                    onClick={clearTransaction}
                  >
                    Clear Transaction
                  </ButtonMUI>
                </Col>
                <Col>
                  <ButtonMUI startIcon={<IconFile3d />} onClick={postTransaction}>
                    Post Transaction
                  </ButtonMUI>
                </Col>
              </Row>

              <Table
                style={{
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                columns={columns}
                dataSource={tableData}
                className="robotFont"
                pagination={false}
              />
            </Paper>
          </Col>
        </Row>
      </Form>
    </ConfigProvider>
  );
};

export default TransmittalForm;
