import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box, Chip } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconEdit, IconEye, IconPlus, IconTrash } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';

type IssuanceReturnsEntryTransactionsApiResponse = {
  data: Array<IssuanceReturnsEntryTransactions>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type IssuanceReturnsEntryTransactions = {
  id: number;
  name: string;
  description: string;
  customer: any;
  received_by: any;
};

type IssuanceReturnEntryTransactionsTableProps = {
  onRefetchRef: React.RefObject<{ refetch: () => void }>;
};
const IssuanceReturnsEntryTransactionsTable: React.FC<
  IssuanceReturnEntryTransactionsTableProps
> = ({ onRefetchRef }) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openIssuanceReturnsEntryTransactionModal, setOpenIssuanceReturnsEntryTransactionModal] =
    useState(false);
  const [purchaseEntryTransactionData, setIssuanceReturnsEntryTransactionData] =
    useState<IssuanceReturnsEntryTransactions | null>(null);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  console.warn('DarkMode: ', isDarkMode);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IssuanceReturnsEntryTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/ir_transactions`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as IssuanceReturnsEntryTransactionsApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching offices:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<IssuanceReturnsEntryTransactions>[]>(
    () => [
      {
        accessorKey: 'slip_number',
        header: 'Slip Number',
        size: 180,
      },
      // {
      //   accessorKey: 'reference_number',
      //   header: 'Reference Number',
      //   size: 180,
      // },
      // {
      //   accessorKey: 'issuance_id',
      //   header: 'Issuance Reference Number',
      //   size: 180,
      // },
      {
        accessorKey: 'customer',
        header: 'Customer',
        size: 180,
        Cell: ({ row }) => row.original.customer.name,
      },
      {
        accessorKey: 'received_by',
        header: 'Status',
        size: 180,
        Cell: ({ row }) => (
          (row.original !== null ? (<Chip label="COMPLETED" color="success"></Chip>) : <Chip label="PENDING"></Chip>)

        ),
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <Button
                onClick={() => handleViewIssuanceReturnsEntryTransaction(row.original)}
                variant="outlined"
              >
                <IconEye />
              </Button>
            </Grid>
          </Grid>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewIssuanceReturnsEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/issuance-return-entry-view-transaction');
  };

  const handleAddIssuanceReturnsEntryTransaction = useCallback(() => {
    setIssuanceReturnsEntryTransactionData(null);
    setIsEditing(false);
    setOpenIssuanceReturnsEntryTransactionModal(true);
  }, []);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const IssuanceReturnsEntryTransactionsView: React.FC<IssuanceReturnEntryTransactionsTableProps> = ({
  onRefetchRef,
}) => (
  <QueryClientProvider client={queryClient}>
    <IssuanceReturnsEntryTransactionsTable onRefetchRef={onRefetchRef} />
  </QueryClientProvider>
);

export default IssuanceReturnsEntryTransactionsView;
