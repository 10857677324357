import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Divider,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  DialogActions,
  TextField,
  InputAdornment,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { ReactToPrint } from 'react-to-print';
import './print.css'; // Import the CSS file
import {
  Popover,
  Button as ButtonAnt,
  Form,
  Select,
  Input,
  Steps,
  Popconfirm,
  message,
} from 'antd';
import axiosClient from 'src/axios_client';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/dark-logo.svg';
import { ReactComponent as LogoDarkRTL } from 'src/assets/images/logos/dark-rtl-logo.svg';
import { ReactComponent as LogoLight } from 'src/assets/images/logos/light-logo.svg';
import { ReactComponent as LogoLightRTL } from 'src/assets/images/logos/light-logo-rtl.svg';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { formatDateWithTime } from 'src/globals/PublicFunctions';

type InvoiceData = {
  id: number;
  invoice_number: string;
  slip_number: any;
  status: any;
  reference_number: string;
  date_of_issuance: string;
  stock_location_record: any;
  customer_code: string;
  customer_name: string;
  customer_address: string;
  customer_contact_person: string;
  transactions: any[];
  created_by_userid: string;
  created_by_name: string;
  subtotal: number;
  released_by: any;
  released_at: any;
  checked_by: any;
  checked_at: any;
  checked_note: any;
  created_by: any;
  created_at: any;
  received_at: any;
  newSubTotal: any;
  total_lost_amount: any;
  released_note: any;
};

interface ErrorResponse {
  message: string;
  error: string;
}

interface GridItemProps {
  data: InvoiceData;
  copy: any;
  userlevel: any;
  isConfirmed: any;

  handleNoticeSelect: (productId: string, noticeId: string, keys: string[]) => void;
  handleNoteChange: (productId: string, note: string) => void;
  selectedNotices: {
    [key: string]: { noticeId: string; keys: string[]; note?: string };
  };
}

const { Option } = Select;

const GridItem: FC<GridItemProps> = ({
  data,
  copy,
  isConfirmed,
  userlevel,
  handleNoticeSelect,
  handleNoteChange,
  selectedNotices,
}) => {
  const { formatWithPrefix } = useFormattedNumber();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  console.log(data);
  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  const lostQuantityArrays = data.transactions.filter((data: any) => data.lostQuantity > 0)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}

            {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            {data.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data.reference_number} <br />
            Issuance Date: {data.date_of_issuance}
            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              PRODUCT NAME
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              MODEL
            </TableCell>
            {isValidUserLevel(userlevel) && (
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                LOCATION
              </TableCell>
            )}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SERIAL NO.
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              QTY
            </TableCell>
            {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              UNIT
            </TableCell> */}
            {isValidUserLevel(userlevel) && (
              <>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  COST
                </TableCell>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  AMOUNT
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              {isValidUserLevel(userlevel) && (
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.location?.name ? (
                    <>
                      {transaction.location.name}
                      {transaction.divider} Floor {transaction.floor}
                    </>
                  ) : (
                    'No stock location record'
                  )}
                </TableCell>
              )}
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.takenQuantity} {transaction.product_unit}
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_unit}
              </TableCell> */}
              {isValidUserLevel(userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.takenQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
              {userlevel === 'stockman' && data.status !== 'posted' && !isConfirmed && (
                <TableCell sx={{ py: 1, displayPrint: 'none' }} className="fontSize8pt">
                  {transaction.notice !== 0 && (
                    <Popover
                      content={
                        <Form layout="vertical">
                          <Form.Item
                            name="reason"
                            label="Select Reason"
                            rules={[{ required: true, message: 'Please select a reason' }]}
                          >
                            <Select
                              placeholder="Select a reason"
                              onChange={(value) =>
                                handleNoticeSelect(transaction.product_id, value, transaction.keys)
                              }
                            >
                              <Option value="0">Ok</Option>
                              <Option value="1">Physical Stock Mismatch</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="note"
                            label="Note"
                            rules={[
                              {
                                required: selectedNotices[transaction.product_id]?.noticeId === '1',
                                message: 'Note is required for Physical Stock Mismatch',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter note"
                              disabled={selectedNotices[transaction.product_id]?.noticeId !== '1'}
                              onChange={(e) =>
                                handleNoteChange(transaction.product_id, e.target.value)
                              }
                            />
                          </Form.Item>
                        </Form>
                      }
                      title="Notice"
                      trigger="click"
                    >
                      <ButtonAnt>Notice</ButtonAnt>
                    </Popover>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}

          {lostQuantityArrays.map((transaction, index) => (
            <TableRow key={index} sx={{ '.MuiTableCell-root': { color: 'red', fontWeight: 'bold' } }}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                LOST IN TRANSIT
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.lostQuantity} {transaction.product_unit} LOST
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
               {transaction.product_unit}
             </TableCell> */}
              {isValidUserLevel(userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.lostQuantity * transaction.price)}
                  </TableCell>
                </>
              )}


            </TableRow>
          ))}

          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data.transactions.length}
              </Typography>
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
            {isValidUserLevel(userlevel) && <TableCell sx={{ py: 1 }}></TableCell>}
            <TableCell sx={{ py: 1 }}></TableCell>
            {/* <TableCell sx={{ py: 1 }}></TableCell> */}
            <TableCell sx={{ py: 1 }}></TableCell>
            {isValidUserLevel(userlevel) && (
              <>
                <TableCell sx={{ py: 1 }}></TableCell>
                <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
                  {formatWithPrefix(data.newSubTotal)}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
      >
        {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
        <Grid item xs={5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {formatDateWithTime(data.created_at)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: {userData?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

interface ReportItemProps {
  data: InvoiceData;
  copy: any;
  userlevel: any;
  reportClick: any;
  cancelClick: any;
}
const ReportItems: FC<ReportItemProps> = ({
  data,
  copy,
  reportClick,
  cancelClick,
  userlevel,
}) => {
  const { formatWithPrefix } = useFormattedNumber();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  console.log(data);
  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  useEffect(() => {
    console.log(data);

  }, [data])

  const [transactionsWithLostQuantity, setTransactionsWithLostQuantity] = useState(
    data.transactions.map((transaction) => ({
      ...transaction,
      lostQuantity: 0, // Add lostQuantity initialized to 0
    }))
  );

  // Handle change for lostQuantity TextField
  const handleLostQuantityChange = (index: any, event: any) => {
    const value = parseInt(event.target.value, 10);
    const maxQuantity = transactionsWithLostQuantity[index].takenQuantity;
    if (value >= 0 && value <= maxQuantity) {
      setTransactionsWithLostQuantity((prevTransactions) =>
        prevTransactions.map((transaction, idx) =>
          idx === index ? { ...transaction, lostQuantity: value } : transaction
        )
      );
    }
    else {
      console.log("Sobra na yan insan");
    }
  };

  const handlePost = () => {
    reportClick(transactionsWithLostQuantity);
  }
  const handleCancel = () => {
    cancelClick();
  }

  return (
    <Box >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            {data.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data.reference_number} <br />
            Issuance Date: {data.date_of_issuance}
            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              PRODUCT NAME
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              MODEL
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SERIAL NO.
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              QTY
            </TableCell>
            {isValidUserLevel(userlevel) && (
              <>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  COST
                </TableCell>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  AMOUNT
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionsWithLostQuantity.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1, maxWidth: 80 }} className="fontSize8pt" >
                <CustomTextField
                  id="quotation_quantity"
                  size="small"
                  variant="standard"
                  type="number"
                  value={transaction.lostQuantity}
                  onChange={(e: any) => handleLostQuantityChange(index, e)}
                  InputProps={{
                    min: 0, // Minimum value is 0
                    max: transaction.takenQuantity, // Maximum value is takenQuantity
                    endAdornment: <InputAdornment position="start">{transaction.product_unit}</InputAdornment>,
                  }}
                  fullWidth
                />

                {/* {transaction.takenQuantity} {transaction.product_unit} */}
              </TableCell>
              {isValidUserLevel(userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.takenQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data.transactions.length}
              </Typography>
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
            {isValidUserLevel(userlevel) && <TableCell sx={{ py: 1 }}></TableCell>}
            <TableCell sx={{ py: 1 }}></TableCell>
            {isValidUserLevel(userlevel) && (
              <>
                <TableCell sx={{ py: 1 }}></TableCell>
                <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
                  {formatWithPrefix(data.subtotal)}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>

      <Grid container columnSpacing={1}>


        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
            {formatDateWithTime(data.created_at)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            User: {userData?.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontSize: '10pt' }} color='error' className="fontSize8pt">
            Note: Input 0 if theres no lost
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container columnSpacing={1} justifyContent={'end'}>
            <Grid item>
              <Button onClick={handleCancel}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button autoFocus color='error' onClick={handlePost}>
                Report
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
};

const Invoice: FC<{ data: InvoiceData; userlevel: any; onAcknowledgeSuccess?: () => void }> = ({
  data,
  userlevel,
  onAcknowledgeSuccess,
}) => {

  const componentRef = useRef(null);
  const [selectedNotices, setSelectedNotices] = useState<{
    [key: string]: { noticeId: string; keys: string[]; note?: string };
  }>({});

  const [refreshKey, setRefreshKey] = useState(0);

  const handleNoticeSelect = (productId: string, noticeId: string, keys: string[]) => {
    setSelectedNotices((prev) => ({
      ...prev,
      [productId]: { ...prev[productId], noticeId, keys },
    }));
  };

  const handleNoteChange = (productId: string, note: string) => {
    setSelectedNotices((prev) => ({
      ...prev,
      [productId]: { ...prev[productId], note },
    }));
  };

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirmNotices = async () => {
    const payload = {
      transactionId: data.id,
      products: Object.entries(selectedNotices).map(([productId, { noticeId, keys, note }]) => ({
        productId: parseInt(productId, 10),
        noticeId: parseInt(noticeId, 10),
        keys: keys.map((key) => parseInt(key, 10)),
        note: note || '',
      })),
    };
    console.log('Confirming notices with payload:', payload);
    try {
      const response = await axiosClient.post('/confirm-notices', payload);
      setIsConfirmed(true);
      setTransactionId(data.id);
      setRefreshKey((prevKey) => prevKey + 1);
      navigate('/issuance-entry-view-transaction');
    } catch (error) {
      console.error('Failed to confirm notices:', error);
    }
  };

  const getCurrentStep = (data: InvoiceData): number => {
    if (data.received_at) {
      return 4; // Confirmed
    } else if (data.checked_by.name && data.checked_at) {
      return 3; // Confirmed
    } else if (data.released_by.name) {
      return 2; // Received by Checker
    } else if (data.created_at) {
      return 1; // Created Return Transaction
    }
    return 0; // Default to the first step
  };

  const [note, setNote] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const acknowledge = async () => {
    if (!data.id) return;

    try {
      const response = await axiosClient.post(`/ie_transactions/${data.id}/acknowledge`, {
        userlevel: userlevel,
        note: note,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledgeSuccess?.();
    } catch (error) {
      console.error('Acknowledgment failed', error);
      const errorMessage =
        (error as { response?: { data?: ErrorResponse } }).response?.data?.error ||
        'An unexpected error occurred';
      messageApi.open({
        type: 'error',
        content: `Acknowledgment unsuccessful: ${errorMessage}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const reportTransaction = (transactionsWithLostQuantity: any) => {

    const newAmount = data.subtotal;
    let minusThis = 0;

    let totalItemsMissing = 0;
    const reportTransact = transactionsWithLostQuantity.map((transactions: any) => {

      if (transactions.lostQuantity > 0) {
        minusThis = minusThis + (transactions.lostQuantity * transactions.price)
        totalItemsMissing = totalItemsMissing + transactions.lostQuantity;
      }

      const array = { ...transactions };

      delete array.key;
      delete array.product_name;
      delete array.product_model;
      delete array.price;
      delete array.notice;
      delete array.product_unit;
      delete array.keys;

      return array;
    });


    const passThisData = {
      'total_lost_amount': minusThis,
      'total_missing_items': totalItemsMissing,
      'stock_location_record': reportTransact,
    }
    
    const response = axiosClient
      .post(`/ie_transactions/report_missing/${data.id}`, passThisData)
      .then((response) => {

        if (response.status == 201 || response.status == 200) {
          setSucess('REPORT SUCCESSFULLY SUBMITTED!');
          data.newSubTotal = newAmount - minusThis;
          data.transactions = transactionsWithLostQuantity;
          data.received_at = dayjs().toISOString;

          setTimeout(() => {
            setSucess('');
          }, 3000)
          setDialogOpen(false);
        } else if (response.status == 500) {
          setError('REPORT ENCOUNTERED SOME ERRORS');
          setTimeout(() => {
            setError('');
          }, 3000)
          setDialogOpen(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
        setDialogOpen(false);
        setTimeout(() => {
          setError('');
        }, 3000)
        console.error('Failed to post transaction:', error);
      });

  }


  return (
    <Box
      key={refreshKey}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      {contextHolder}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {sucess && (
            <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
              {sucess}
            </Alert>
          )}

          {error && (
            <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
              {error}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={9} ref={componentRef}>
          <Box>
            <GridItem
              data={data}
              copy={'Receiver Copy'}
              isConfirmed={isConfirmed}
              userlevel={userlevel}
              handleNoticeSelect={handleNoticeSelect}
              selectedNotices={selectedNotices}
              handleNoteChange={handleNoteChange}
            />
          </Box>


          {userlevel != 'customer' &&

            <>
              <div className="pageBreak"></div>
              <Box sx={{ display: 'none', displayPrint: 'block' }}>
                <GridItem
                  data={data}
                  copy={'Encoder Copy'}
                  isConfirmed={isConfirmed}
                  userlevel={userlevel}
                  handleNoticeSelect={handleNoticeSelect}
                  selectedNotices={selectedNotices}
                  handleNoteChange={handleNoteChange}
                />
              </Box>
              <div className="pageBreak"></div>
              <Box sx={{ display: 'none', displayPrint: 'block' }}>
                <GridItem
                  data={data}
                  copy={'Warehouse Copy'}
                  isConfirmed={isConfirmed}
                  userlevel={userlevel}
                  handleNoticeSelect={handleNoticeSelect}
                  selectedNotices={selectedNotices}
                  handleNoteChange={handleNoteChange}
                />
              </Box>
              <div className="pageBreak"></div>
              <Box sx={{ display: 'none', displayPrint: 'block' }}>
                <GridItem
                  data={data}
                  copy={'Checker Copy'}
                  userlevel={userlevel}
                  isConfirmed={isConfirmed}
                  handleNoticeSelect={handleNoticeSelect}
                  selectedNotices={selectedNotices}
                  handleNoteChange={handleNoteChange}
                />
              </Box>
              <div className="pageBreak"></div>
              <Box sx={{ display: 'none', displayPrint: 'block' }}>
                <GridItem
                  data={data}
                  copy={'Accounting Copy'}
                  isConfirmed={isConfirmed}
                  userlevel={userlevel}
                  handleNoticeSelect={handleNoticeSelect}
                  selectedNotices={selectedNotices}
                  handleNoteChange={handleNoteChange}
                />
              </Box>
            </>
          }

        </Grid>

        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {data.status === 'floating' && data.released_at == null && userlevel === 'stockman' && (
              <Button
                variant="outlined"
                color="error"
                sx={{ py: 1.5, px: 3, borderRadius: 1, display: isConfirmed ? 'none' : 'flex' }}
                onClick={handleConfirmNotices}
              >
                Confirm Notice
              </Button>
            )}

            <Steps
              progressDot
              current={getCurrentStep(data)}
              direction="vertical"
              items={[
                {
                  title: 'Issuance Created',
                  description: `Created by ${data.created_by.name} on ${dayjs(
                    data.created_at,
                  ).format('MMMM DD, YYYY HH:mm:ss')}`,
                },

                {
                  title: (
                    <>
                      <Popover
                        content={
                          data.released_note === '' || data.released_note == null
                            ? 'Notes will be here'
                            : data.released_note
                        }
                      >
                        Stockman Receipt
                      </Popover>
                    </>
                  ),
                  description:
                    data.released_by.name && data.released_at
                      ? `Released by ${data.released_by.name} on ${dayjs(data.released_at).format(
                        'MMMM DD, YYYY HH:mm:ss',
                      )}`
                      : 'Pending',
                },
                {
                  // title: 'Checker Review',
                  title: (
                    <>
                      <Popover
                        content={
                          data.checked_note === '' || data.checked_note == null
                            ? 'Notes will be here'
                            : data.checked_note
                        }
                      >
                        Checker Review
                      </Popover>
                    </>
                  ),
                  description:
                    data.checked_by.name && data.checked_at
                      ? `Cross-Checked by ${data.checked_by.name} on ${dayjs(
                        data.checked_at,
                      ).format('MMMM DD, YYYY HH:mm:ss')}`
                      : 'Pending',
                },
                {
                  title: (
                    <>
                      <Popover content={data.received_at === '' ? 'OK' : data.received_at}>
                        Receiving
                      </Popover>
                    </>
                  ),
                  description: data.received_at
                    ? `Received on ${dayjs(data.received_at).format('MMMM DD, YYYY HH:mm:ss')}`
                    : 'Pending',
                },
                {
                  title: 'Confirmation',
                  description: data.received_at ? 'Completed' : 'Pending',
                },
              ]}
            />

            {(userlevel === 'checker' ||
              userlevel === 'stockman' ||
              userlevel === 'customer' ||
              userlevel === 'superadmin') && (
                <Popconfirm
                  title="Acknowledge Issuance"
                  description={
                    <>
                      <p>
                        Are you sure you want to{' '}
                        {userlevel === 'checker'
                          ? 'RELEASE (SECOND LEVEL)'
                          : userlevel === 'stockman'
                            ? 'RELEASE (FIRST LEVEL)'
                            : userlevel === 'customer'
                              ? 'RECEIVE'
                              : ''}{' '}
                        this issuance?
                      </p>
                      <Input.TextArea
                        placeholder="Add a note (optional)"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        rows={4}
                      />
                    </>
                  }
                  onConfirm={acknowledge}
                  okText="Yes"
                  cancelText="No"
                  placement="right"
                >
                  <Button
                    disabled={
                      isAcknowledged ||
                      (userlevel === 'checker' && data?.checked_by.id !== null) ||
                      (userlevel === 'stockman' && data?.released_by.id !== null) ||
                      (userlevel === 'customer' && data?.received_at !== null) ||
                      (userlevel === 'superadmin' &&
                        data?.checked_by.id !== null &&
                        data?.released_by.id !== null)
                    }
                  >
                    {(userlevel === 'checker' && data?.checked_by.id !== null) ||
                      (userlevel === 'stockman' && data?.released_by.id !== null) ||
                      (userlevel === 'customer' && data?.received_at !== null) ||
                      (userlevel === 'superadmin' &&
                        data?.checked_by.id !== null &&
                        data?.released_by.id !== null)
                      ? 'Acknowledged'
                      : 'Acknowledge'}
                  </Button>
                </Popconfirm>
              )}

            {
              data.checked_by.name && !data.received_at && ((userlevel === 'customer') || (userlevel === 'superadmin')) ?
                <Button variant='text' color='error' onClick={handleClickOpen}>
                  Report Missing Items
                </Button> : ''
            }


            <ReactToPrint
              trigger={() => (
                <Button variant="contained" size='small' color='primary'>
                  Print
                </Button>
              )}
              content={() => {
                return componentRef.current;
              }}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .pageBreak {
                    page-break-after: always;
                  }
                  .fontSize8pt {
                    font-size: 8pt !important;
                  }
                  tbody tr:not(:last-child) td {
                    border-bottom: 1px solid #acacac;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Report Details'}</DialogTitle>
        <DialogContent>
          <Backdrop
            sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ReportItems data={data} copy={''} userlevel={userlevel} reportClick={reportTransaction} cancelClick={handleDialogClose} />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={reportTransaction} autoFocus color='error'>
            Report
          </Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
};

export default Invoice;
