import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box, Skeleton } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons';
import CustomerDialog from './CustomerDialog'; // Import the CustomerDialog component

type CustomersApiResponse = {
  data: Array<Customers>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type Customers = {
  id: number;
  name: string;
  code: string;
  address: string;
  email: string;
  contact_person: string;
  mobile_number: string;
  is_individual: any;
};

const CustomersTable = () => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [CustomerData, setCustomerData] = useState<Customers | null>(null);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<CustomersApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/customers`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as CustomersApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<Customers>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        minSize: 10,
        maxSize: 10,
        size: 10,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 180,
      },
      {
        accessorKey: 'code',
        header: 'Customer Code',
        size: 180,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 180,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <Button onClick={() => handleEditCustomer(row.original)} variant="outlined">
                <IconEdit />
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleDeleteCustomer(row.original.id)} variant="outlined">
                <IconTrash />
              </Button>
            </Grid>
          </Grid>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Add New Customer">
          <IconButton onClick={handleAddCustomer}>
            <IconPlus />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const handleEditCustomer = useCallback((record: any) => {
    setCustomerData(record);
    setIsEditing(true);
    setOpenCustomerModal(true);
  }, []);

  const handleAddCustomer = useCallback(() => {
    setCustomerData(null);
    setIsEditing(false);
    setOpenCustomerModal(true);
  }, []);

  const handleCloseCustomerModal = useCallback(() => {
    setOpenCustomerModal(false);
  }, []);

  const handleSubmit = useCallback((payload: any) => {
    console.log('Payload:', payload);
    setOpenCustomerModal(false);
  }, []);

  const handleDeleteCustomer = useCallback((id: any) => {
    console.log('Delete action for record ID:', id);
  }, []);

  return (
    <>
      <CustomerDialog
        isOpen={openCustomerModal}
        isEditing={isEditing}
        initialData={CustomerData}
        onClose={handleCloseCustomerModal}
        onSubmit={handleSubmit}
        refetch={refetch}
      />

      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <MaterialReactTable table={table} />
      )}
    </>
  );
};

const queryClient = new QueryClient();

const CustomersView = () => (
  <QueryClientProvider client={queryClient}>
    <CustomersTable />
  </QueryClientProvider>
);

export default CustomersView;
