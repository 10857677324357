import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box, Skeleton } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconEdit, IconEye, IconPlus, IconTrash } from '@tabler/icons';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/TransmittalTransactionContext';

type TransmittalTransactionsApiResponse = {
  data: Array<TransmittalTransactions>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type TransmittalTransactions = {
  id: number;
  log_name: string;
  description: string;
  subject_type: string;
  event: string;
  created_by: any;
  name: string;
  destination: number;
  supply_center: any;
  created_at: string;
  updated_at: string;
};

export interface TransmittalTransactionsRef {
  refetch: () => void;
}

const TransmittalTransactionsTable = forwardRef<TransmittalTransactionsRef, {}>((props, ref) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<TransmittalTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/te_transactions`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as TransmittalTransactionsApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching offices:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewTrasmittal = (row: any) => {
    setTransactionId(row.id);
    navigate('/transmittal-viewer');
  };

  const columns = useMemo<MRT_ColumnDef<TransmittalTransactions>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        size: 5,
      },
      {
        accessorKey: 'pickup_by',
        header: 'Picker',
        size: 180,
      },
      {
        accessorKey: 'created_by',
        header: 'Sender',
        size: 180,
        Cell: ({ row }) => row.original.created_by.name,
      },
      {
        accessorKey: 'supply_center',
        header: 'Destination',
        size: 180,
        Cell: ({ row }) =>
          row.original.supply_center.name,
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 180,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <Button onClick={() => handleViewTrasmittal(original)} variant="outlined">
              <IconEye />
            </Button>
          );
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  useImperativeHandle(ref, () => ({
    refetch: () => refetch(),
  }));

  return isLoading ? (
    <Skeleton variant="rectangular" width="100%" height={400} />
  ) : (
    <MaterialReactTable table={table} />
  );
});

const queryClient = new QueryClient();

const TransmittalTransactions = forwardRef<TransmittalTransactionsRef, {}>((props, ref) => (
  <QueryClientProvider client={queryClient}>
    <TransmittalTransactionsTable ref={ref} />
  </QueryClientProvider>
));

export default TransmittalTransactions;
