import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  // const token = localStorage.getItem("ACCESS_TOKEN");
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept = 'application/json';
  config.headers.ContentType = 'application/json';

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response.status == 401) {
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('admin');
      localStorage.removeItem('userDetails');
      localStorage.removeItem('userAllDetails');
      localStorage.removeItem('token');
      localStorage.removeItem('pusherTransportNonTLS');
      
    }

    throw error;
  },
);

export default axiosClient;
