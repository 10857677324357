import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';

const { Title, Text } = TypographyAnt;
type SelectedCustomerSalesApiResponse = {
  customer_details: {
    id: number;
    name: string;
  };
  data: Array<SelectedCustomerSales>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type SelectedCustomerSales = {
  id: number;
  name: string;
  code: string;
  purchase_count: string;
  total_amount: string;
};

const PrintableTable = React.forwardRef<
  HTMLDivElement,
  {
    data: SelectedCustomerSales[];
    columnsPrint: any[];
    dateRange: string;
    customer_name: string;
  }
>(({ data, columnsPrint, dateRange, customer_name }, ref) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <div ref={ref} style={{ margin: '0 20px' }}>
      <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '20px' }}>
        <Row>
          <Col span={12}>
            <Space direction="vertical" size={0}>
              <Title level={4} style={{ margin: 0 }}>
                RAEL KITZ CORPORATION - WMS IS
              </Title>
              <Text type="secondary">
                RRF Building, San Miguel Calasiao, Pangasinan
                <br />
                Tel No: +1 (123) 456 7891, +44 (876) 543 2198
              </Text>
            </Space>
          </Col>

          <Col span={12} style={{ left: '100px' }}>
            {customizer.activeMode === 'dark' ? (
              <img src={LogoLight} alt="Logo" height={'40px'} />
            ) : (
              <img src={LogoDark} alt="Logo" height={'40px'} />
            )}
          </Col>
        </Row>

        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Purchases Report from [Customer] {customer_name}
          </Title>
          <Text style={{ fontSize: '14px' }}>{dateRange}</Text>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellFontSizeSM: 10,
                borderColor: '#403C3C',
                headerColor: '#403C3C',
                headerBg: '#ebebeb',
              },
            },
          }}
        >
          <Table
            columns={columnsPrint}
            dataSource={data}
            pagination={false}
            bordered
            size="small"
            rowKey={(record) => record.id}
          />
        </ConfigProvider>

        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '20px',
            right: '20px',
            textAlign: 'center',
            fontSize: '10px',
            color: '#666',
          }}
        >
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} by{' '}
          {process.env.REACT_APP_DEVELOPER}
        </div>
      </Space>
    </div>
  );
});

type SelectedCustomerSalesTableProps = {
  customerId: number;
  dateRange: any;
};

const SelectedCustomerSalesTable: React.FC<SelectedCustomerSalesTableProps> = ({
  customerId,
  dateRange,
}) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  const { formatWithPrefix } = useFormattedNumber();

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  console.log('CustomerID: ', customerId);
  console.log('dateRange: ', dateRange);

  useEffect(() => {
    if (!customerId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-sales-by-customer');
    }
  }, [customerId, navigate]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // Define filter options
  const filterOptions = [
    { label: 'Unchecked', value: 'filter_checked' },
    { label: 'Unreleased', value: 'filter_released' },
    { label: 'Unreceived', value: 'filter_received' },
    { label: 'All Incomplete', value: 'filter_all_incomplete' },
    { label: 'Complete', value: 'filter_complete' },
  ];

  const {
    data: { data = [], pagination: meta, customer_details } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<SelectedCustomerSalesApiResponse>({
    queryKey: [
      'selected-customer-purchase',
      customerId,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      dateRange,
      selectedFilters,
    ],
    queryFn: async () => {
      if (!customerId || !dateRange[0] || !dateRange[1]) {
        throw new Error('Customer ID and date range are required');
      }

      const fetchURL = new URL(
        `${process.env.REACT_APP_API_SERVER}/customer-sales-report/${customerId}`,
      );
      fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      // Set filter parameters based on selected filters
      filterOptions.forEach((option) => {
        fetchURL.searchParams.set(option.value, selectedFilters.includes(option.value).toString());
      });
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      fetchURL.searchParams.set('start_date', startDate.toISOString());
      fetchURL.searchParams.set('end_date', endDate.toISOString());

      try {
        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as SelectedCustomerSalesApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching selected customer purchase report:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
    enabled: !!customerId && !!dateRange[0] && !!dateRange[1], // Only run the query if customerId and dateRange are available
  });

  const handleViewPurchaseEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/issuance-entry-view-transaction');
  };

  const customerName = customer_details?.name || '';

  const columns = useMemo<MRT_ColumnDef<SelectedCustomerSales>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewPurchaseEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'slip_number',
        header: 'Slip #',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'date_of_issuance',
        header: 'Purchase Date',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return <span>{renderedCellValue}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.total_amount,
        id: 'total_amount',
        header: 'Total Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: 'incomplete',
        header: 'Status',
        size: 150,
        Cell: ({ cell }) => {
          const incomplete = cell.getValue<string[]>();
          if (incomplete.length === 0) {
            return <Chip label="Complete" color="success" size="small" />;
          } else {
            return <Chip label="Incomplete" color="error" size="small" />;
          }
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const printableTableRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
    pageStyle: `
    @media print {
      @page {
        size: landscape;
        margin: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
      }
    }
  `,
  });

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        {/* <DateSelector onDateChange={setDateRange} />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip> */}

        <Autocomplete
          multiple
          limitTags={2}
          id="filter-tags"
          options={filterOptions}
          getOptionLabel={(option) => option.label}
          value={filterOptions.filter((option) => selectedFilters.includes(option.value))}
          onChange={(event, newValue) => {
            setSelectedFilters(newValue.map((item) => item.value));
          }}
          renderTags={(value, getTagProps) =>
            value
              .slice(0, 2)
              .map((option, index) => (
                <Chip key={option.value} label={option.label} />
              ))
              .concat(value.length > 2 ? <span key="more">+{value.length - 2} more</span> : [])
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter Status"
              placeholder="Select filters"
            />
          )}
          sx={{ width: 500 }}
        />

        <Tooltip arrow title="Print">
          <IconButton onClick={handlePrint}>
            <PrintSharp />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download as Document">
          <IconButton disabled>
            <Download />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Send to Email">
          <IconButton disabled>
            <Email />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const columnsPrint = useMemo(
    () => [
      {
        title: 'Reference #',
        dataIndex: 'reference_number',
        key: 'reference_number',
      },
      {
        title: 'Slip #',
        dataIndex: 'slip_number',
        key: 'slip_number',
      },
      {
        title: 'Purchase Date',
        dataIndex: 'date_of_issuance',
        key: 'date_of_issuance',
      },
      {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        key: 'total_amount',
        render: (text: number) => (
          <>
            <span style={{ textAlign: 'right', display: 'block' }}>{formatWithPrefix(text)}</span>
          </>
        ),
      },
    ],
    [formatWithPrefix],
  );

  const formattedPrintData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      total_amount: item.total_amount,
    }));
  }, [data, formatWithPrefix]);

  const formattedDateRange = useMemo(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('MMMM DD, YYYY');
      const endDate = dateRange[1].format('MMMM DD, YYYY');
      return `[${startDate} - ${endDate}]`;
    }
    return '';
  }, [dateRange]);

  return (
    <>
      <MaterialReactTable table={table} />

      <div style={{ display: 'none' }}>
        <PrintableTable
          ref={printableTableRef}
          data={formattedPrintData}
          columnsPrint={columnsPrint}
          dateRange={formattedDateRange}
          customer_name={customerName}
        />
      </div>
    </>
  );
};

const queryClient = new QueryClient();

const SelectedCustomerSalesView: React.FC<SelectedCustomerSalesTableProps> = ({
  customerId,
  dateRange,
}) => (
  <QueryClientProvider client={queryClient}>
    <SelectedCustomerSalesTable customerId={customerId} dateRange={dateRange} />
  </QueryClientProvider>
);

export default SelectedCustomerSalesView;
