import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/ProductSalesContext';
import BrandLogo from 'src/assets/images/svgs/rkc_logo.svg';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; // Import the English locale
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Manila');

const { Title, Text } = TypographyAnt;
type SelectedProductSalesApiResponse = {
  product_details: {
    id: number;
    name: string;
  };
  data: Array<SelectedProductSales>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type SelectedProductSales = {
  id: number;
  name: string;
  code: string;
  product_price: string;
  product_total_amount: string;
};

const PrintableTable = React.forwardRef<
  HTMLDivElement,
  {
    data: SelectedProductSales[];
    columnsPrint: any[];
    dateRange: string;
    product_name: string;
  }
>(({ data, columnsPrint, dateRange, product_name }, ref) => {

  const customizer = useSelector((state: AppState) => state.customizer);

  return (<div ref={ref} style={{ margin: '0 20px' }}>
    <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '20px' }}>

      <Row>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>
              RAEL KITZ CORPORATION - WMS IS
            </Title>
            <Text type="secondary">
              RRF Building, San Miguel Calasiao, Pangasinan
              <br />
              Tel No: +1 (123) 456 7891, +44 (876) 543 2198
            </Text>
          </Space>
        </Col>

        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {customizer.activeMode === 'dark' ? (
            <img src={LogoLight} alt="Logo" height={'40px'} />
          ) : (
            <img src={LogoDark} alt="Logo" height={'40px'} />
          )}
        </Col>
      </Row>

      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
      >
        <Title level={5} style={{ margin: 0 }}>
          Saless Report from [Product] {product_name}
        </Title>
        <Text style={{ fontSize: '14px' }}>{dateRange}</Text>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellFontSizeSM: 10,
              borderColor: '#403C3C',
              headerColor: '#403C3C',
              headerBg: '#ebebeb',
            },
          },
        }}
      >
        <Table
          columns={columnsPrint}
          dataSource={data}
          pagination={false}
          bordered
          size="small"
          rowKey={(record) => record.id}
        />
      </ConfigProvider>

      <div
        style={{
          position: 'fixed',
          bottom: '10px',
          left: '20px',
          right: '20px',
          textAlign: 'center',
          fontSize: '10px',
          color: '#666',
        }}
      >
        {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} by{' '}
        {process.env.REACT_APP_DEVELOPER}
      </div>
    </Space>
  </div>)
});

type SelectedProductSalessTableProps = {
  productId: number;
  dateRange: any;
};

const SelectedProductSalessTable: React.FC<SelectedProductSalessTableProps> = ({
  productId,
  dateRange,
}) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  const { formatWithPrefix } = useFormattedNumber();

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: { data = [], pagination: meta, product_details } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<SelectedProductSalesApiResponse>({
    queryKey: [
      'selected-product-purchase',
      productId,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      dateRange,
    ],
    queryFn: async () => {
      if (!productId || !dateRange[0] || !dateRange[1]) {
        throw new Error('Product ID and date range are required');
      }

      const fetchURL = new URL(
        `${process.env.REACT_APP_API_SERVER}/product-sales-report/${productId}`,
      );
      fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

      fetchURL.searchParams.set('start_date', dateRange[0].toISOString());
      fetchURL.searchParams.set('end_date', dateRange[1].toISOString());

      try {
        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as SelectedProductSalesApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching selected product purchase report:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
    enabled: !!productId && !!dateRange[0] && !!dateRange[1], // Only run the query if productId and dateRange are available
  });

  const navigate = useNavigate();
  const { setProductId } = useTransaction();

  const handleViewSalesEntryTransaction = (row: any) => {
    setProductId(row.id);
    navigate('/issuance-entry-view-transaction');
  };

  const productName = product_details?.name || '';

  const columns = useMemo<MRT_ColumnDef<SelectedProductSales>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewSalesEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'slip_number',
        header: 'Slip #',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'quantity_issued',
        header: 'Quantity Issued',
        grow: false,
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.product_price,
        id: 'product_price',
        header: 'Product Price',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.product_total_amount,
        id: 'product_total_amount',
        header: 'Product Total Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const printableTableRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
    pageStyle: `
    @media print {
      @page {
        size: landscape;
        margin: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
      }
    }
  `,
  });

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        {/* <DateSelector onDateChange={setDateRange} />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip> */}

        <Tooltip arrow title="Print">
          <IconButton onClick={handlePrint}>
            <PrintSharp />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download as Document">
          <IconButton disabled>
            <Download />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Send to Email">
          <IconButton disabled>
            <Email />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const columnsPrint = useMemo(
    () => [
      {
        title: 'Reference #',
        dataIndex: 'reference_number',
        key: 'reference_number',
      },
      {
        title: 'Invoice #',
        dataIndex: 'slip_number',
        key: 'slip_number',
      },
      {
        title: 'Product Price',
        dataIndex: 'product_price',
        key: 'product_price',
        render: (text: number) => (
          <>
            <span style={{ textAlign: 'right', display: 'block' }}>{formatWithPrefix(text)}</span>
          </>
        ),
      },
      {
        title: 'Total Amount',
        dataIndex: 'product_total_amount',
        key: 'product_total_amount',
        render: (text: number) => (
          <>
            <span style={{ textAlign: 'right', display: 'block' }}>{formatWithPrefix(text)}</span>
          </>
        ),
      },
    ],
    [formatWithPrefix],
  );

  const formattedPrintData = useMemo(() => {
    return data.map((product) => ({
      ...product,
      product_total_amount: product.product_total_amount,
    }));
  }, [data, formatWithPrefix]);

  const formattedDateRange = useMemo(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('MMMM DD, YYYY');
      const endDate = dateRange[1].format('MMMM DD, YYYY');
      return `[${startDate} - ${endDate}]`;
    }
    return '';
  }, [dateRange]);

  return (
    <>
      <MaterialReactTable table={table} />

      <div style={{ display: 'none' }}>
        <PrintableTable
          ref={printableTableRef}
          data={formattedPrintData}
          columnsPrint={columnsPrint}
          dateRange={formattedDateRange}
          product_name={productName}
        />
      </div>
    </>
  );
};

const queryClient = new QueryClient();

const SelectedProductSalesView: React.FC<SelectedProductSalessTableProps> = ({
  productId,
  dateRange,
}) => (
  <QueryClientProvider client={queryClient}>
    <SelectedProductSalessTable productId={productId} dateRange={dateRange} />
  </QueryClientProvider>
);

export default SelectedProductSalesView;
