import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import {
  Grid,
  InputAdornment,
  Button,
  Divider,
  IconButton,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  Input,
  Box,
  Autocomplete,
  Alert,
  TextField,
  MenuItem,
  Typography,
  Menu,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { IconHelp } from '@tabler/icons';
import { Link } from 'react-router-dom';
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import axiosClient from 'src/axios_client';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import { IconChevronDown } from '@tabler/icons';
import { LoadingButton } from '@mui/lab';
import imageCompression from 'browser-image-compression';
import useSuggestedSKU from 'src/hooks/useSuggestedSKU';

interface unitType {
  unit: string;
  label: string;
}

interface Dimensions {
  length: string;
  width: string;
  height: string;
  unit: string;
}

const units: unitType[] = [
  { unit: 'kg', label: 'Kilogram' },
  { unit: 'g', label: 'Gram' },
  { unit: 'ft', label: 'Foot' },
  { unit: 'l', label: 'Liter' },
  { unit: 'm', label: 'Meter' },
  { unit: 'pc', label: 'Piece' },
];

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="₱"
      />
    );
  },
);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Item name is required')
    .matches(/^((?![.]).)*$/, 'Name cannot contain (.)'),
  sku: Yup.string().required('SKU is required'),
  // part_no_rkc: Yup.string().required('Part number is required'),
  // part_no_supplier: Yup.string().required('Part number supplier is required'),
  // manufacturer_id: Yup.string().required('Manufacturer name is required'),
  // supplier_id: Yup.string().required('Supplier name is required'),
  brand_id: Yup.string().required('Brand name is required'),
  // location: Yup.string().required('Location is required'),
  // floor: Yup.string().required('Floor Required'),
  category_id: Yup.string().required('Item category is required'),
  buying_price: Yup.string().required('Buying price is required'),
  selling_price: Yup.string().required('Selling price is required'),
  minimum_stock: Yup.string().required('Minimum Quantity order is required'),
});

const FormSeparator = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [manufacturerOptions, setManufacturerOptions] = useState<{ id: string; name: any }[]>([]);
  const [supplierOptions, setSupplierOptions] = useState<{ id: string; name: any }[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<{ id: string; name: any }[]>([]);
  // const [locationOptions, setLocationOptions] = useState<{ id: string; name: any }[]>([]);
  const [brandOptions, setBrandOptions] = useState<{ id: string; name: any }[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [is_returnable, setIsReturnable] = useState(true);
  const [has_serial, setHasSerial] = useState(true);

  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      sku: '',
      model: '',
      part_no_rkc: '',
      part_no_supplier: '',
      description: '',
      unit: '',
      has_serial: 1,
      is_returnable: 1,
      length: '',
      width: '',
      height: '',
      unitSize: '',
      manufacturer_id: '',
      brand_id: '',
      weight: '',
      supplier_id: '',
      category_id: '',
      lead_time: '',
      location: '',
      floor: '',
      buying_price: '',
      selling_price: '',
      image: null,
      minimum_stock: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        formik.setSubmitting(true);

        setSubmitting(true);

        let formattedValues = {
          ...values,
          dimension_type: dimensions.unit,
        };

        if (selectedFile) {
          formattedValues = {
            ...values,
            image: selectedFile,
            dimension_type: dimensions.unit,
          };
          console.log(formattedValues);
        }
        axiosClient
          .post(`/products`, formattedValues, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.status === 201) {
              console.log(response.data.data.message);
              setSucess(response.data.message);
              setError('');
              setTimeout(() => {
                setSucess('');
              }, 5000);
              setSelectedFile(null);

              setSubmitting(false);
              formik.resetForm();
              formik.setSubmitting(false);
            }
          })
          .catch((error) => {
            setSubmitting(false);
            setError(error.response.data.message);
            console.log(error);
          });
      } else {
        console.log('Form invalid');
      }
    },
  });

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // setCompressing(true);
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        // Create a new File object with the correct type and name
        const newFile = new File([compressedFile], file.name, {
          type: compressedFile.type,
          lastModified: new Date().getTime(),
        });

        setSelectedFile(newFile);
      } catch (error) {
        console.error('Error compressing image:', error);
        setError('Error compressing image. Please try again.');
      } finally {
        console.log('HEHEHE');
        // setCompressing(false);
      }
    } else {
      setSelectedFile(null);
    }
  };

  const handleDeleteImage = () => {
    setSelectedFile(null);
  };
  const autocompleteSearchChange = (searchText: any, apiText: any) => {
    setIsSearching(true);
    setIsEmptySearch(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      const value = searchText.target.value;
      fetchOptions(value, apiText);
    }, 500);
  };
  const fetchOptions = async (searchText: string, apiText: string) => {
    try {
      const { status, data } = await axiosClient.get(`/${apiText}`, {
        params: { globalFilter: searchText, per_page: 10 },
      });
      if (status == 200) {
        setIsSearching(false);
        if (data.data.length == 0) {
          setIsEmptySearch(true);
        }
        const fetchedData = data.data.map((innerData: any) => ({
          id: innerData.id,
          name: apiText == 'suppliers' ? `${innerData.code} - ${innerData.name}` : innerData.name,
        }));
        if (apiText == 'manufacturers') {
          setManufacturerOptions(fetchedData);
        } else if (apiText == 'suppliers') {
          setSupplierOptions(fetchedData);
        } else if (apiText == 'brands') {
          setBrandOptions(fetchedData);
        } else if (apiText == 'categories') {
          setCategoryOptions(fetchedData);
        }
        // else if (apiText == 'locations') {
        //   setLocationOptions(fetchedData);
        // }
      }
    } catch (error) {
      console.error('Failed to fetch product options:', error);
    }
  };
  const handleReturnableChange = (event: any) => {
    setIsReturnable(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue('is_returnable', 1);
    } else {
      formik.setFieldValue('is_returnable', 0);
    }
  };
  const handleHasSerialChange = (event: any) => {
    setHasSerial(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue('has_serial', 1);
    } else {
      formik.setFieldValue('has_serial', 0);
    }
  };

  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '', unit: 'in' });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDimensions((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (unit: string) => {
    setDimensions((prevState) => ({ ...prevState, unit }));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const suggestedSKU = useSuggestedSKU(formik.values.name, formik.values.model);

  // Automatically set the SKU field value when the suggested SKU changes
  useEffect(() => {
    // Always update the SKU when suggestedSKU changes
    if (suggestedSKU) {
      formik.setFieldValue('sku', suggestedSKU);
    }
  }, [suggestedSKU]);

  return (
    <div>
      {sucess && (
        <Alert variant="filled" severity="success" sx={{ alignItems: 'center' }}>
          {sucess}
        </Alert>
      )}

      {error && (
        <Alert variant="filled" severity="error" sx={{ alignItems: 'center' }}>
          {error}
        </Alert>
      )}

      <Backdrop
        sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={formik.handleSubmit}>
        {/*---------- first row ----------*/}
        {/*--------------------*/}
        <Grid container padding={3} columnSpacing={2}>
          <Grid item sm={12} lg={5}>
            <Grid container rowSpacing={{ xs: 0, md: 1 }}>
              <Grid item xs={12} sm={3} display="flex" alignItems="center">
                <CustomFormLabel htmlFor="name" sx={{ mt: 0 }}>
                  Name *
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="name"
                  size="small"
                  variant="standard"
                  label="Enter product name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-model" sx={{ mt: 0 }}>
                  Model
                </CustomFormLabel>
                {/* <Tooltip title="the Stock Keeping Unit of the item">
                  <IconButton sx={{ left: '-5px' }}>
                    <IconHelp size={15} /> 
                  </IconButton>
                </Tooltip> */}
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="model"
                  size="small"
                  variant="standard"
                  label="Model of the product."
                  value={formik.values.model}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.model && Boolean(formik.errors.model)}
                  helperText={formik.touched.model && formik.errors.model}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-sku" sx={{ mt: 0 }}>
                  SKU
                </CustomFormLabel>
                <Tooltip title="the Stock Keeping Unit of the item">
                  <IconButton sx={{ left: '-5px' }}>
                    <IconHelp size={15} /> {/* Adjust the size as needed */}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="sku"
                  size="small"
                  variant="standard"
                  label="Stock Keeping Unit of the item"
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sku && Boolean(formik.errors.sku)}
                  helperText={formik.touched.sku && formik.errors.sku}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={8}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0 }}>
                  Brand
                </CustomFormLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                sx={{ height: { lg: '50px' }, alignContent: { lg: 'end' } }}
              >
                <Autocomplete
                  id="brand_id"
                  noOptionsText={
                    isSearching
                      ? 'Searching...'
                      : isEmptySearch
                      ? `Can't find search word`
                      : 'Type to search'
                  }
                  value={formik.values.brand_id || null}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('brand_id', newValue);
                    if (newValue === null) {
                      setBrandOptions([]);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('brand_id', true)}
                  options={brandOptions.map((p) => p.id)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return brandOptions.find((p) => p.id === option)?.name ?? '';
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={formik.touched.brand_id && Boolean(formik.errors.brand_id)}
                      helperText={formik.touched.brand_id && formik.errors.brand_id}
                      onChange={(e: any) => autocompleteSearchChange(e, 'brands')}
                      variant="standard"
                      placeholder="Brand Name"
                      aria-label="Brand Name"
                    />
                  )}
                />

                {/* <CustomTextField
                  id="brand_id"
                  size="small"
                  variant="standard"
                  label="brand_id name (if available)"
                  multiline
                  maxRows={6}
                  value={formik.values.brand_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.brand_id && Boolean(formik.errors.brand_id)}
                  helperText={formik.touched.brand_id && formik.errors.brand_id}
                  fullWidth
                /> */}
              </Grid>

              {/* <Grid
                item
                xs={8}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0 }}>
                  Manufacturer
                </CustomFormLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                sx={{ minHeight: { lg: '50px' }, alignContent: { lg: 'end' } }}
              >
                <Autocomplete
                  id="manufacturer_id"
                  noOptionsText={
                    isSearching
                      ? 'Searching...'
                      : isEmptySearch
                        ? `Can't find search word`
                        : 'Type to search'
                  }
                  value={formik.values.manufacturer_id || null}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('manufacturer_id', newValue);
                    if (newValue === null) {
                      setManufacturerOptions([]);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('manufacturer_id', true)}
                  options={manufacturerOptions.map((p) => p.id)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return manufacturerOptions.find((p) => p.id === option)?.name ?? '';
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={
                        formik.touched.manufacturer_id && Boolean(formik.errors.manufacturer_id)
                      }
                      helperText={formik.touched.manufacturer_id && formik.errors.manufacturer_id}
                      onChange={(e: any) => autocompleteSearchChange(e, 'manufacturers')}
                      variant="standard"
                      placeholder="Manufacturer"
                      aria-label="Manufacturer"
                    />
                  )}
                />
              </Grid> */}

              <Grid
                item
                xs={12}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-country" sx={{ mt: 0 }}>
                  Unit *
                </CustomFormLabel>
                <Tooltip
                  title="the Stock KeThe item will be measured in terms of
                      this unit (e.g.: kg, dozen)eping Unit of the item"
                >
                  <IconButton sx={{ left: '-5px' }}>
                    <IconHelp size={15} /> {/* Adjust the size as needed */}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                sx={{ minHeight: { lg: '50px' }, alignContent: { lg: 'end' } }}
              >
                <Autocomplete
                  id="unit"
                  value={formik.values.unit || ''}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('unit', newValue || formik.values.unit);
                  }}
                  onInputChange={(_, inputValue) => {
                    // Capture the typed value when input changes
                    formik.setFieldValue('unit', inputValue);
                  }}
                  onBlur={() => formik.setFieldTouched('unit', true)}
                  options={units.map((p) => p.unit)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return units.find((p) => p.unit === option)?.label ?? option;
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  freeSolo
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                      variant="standard"
                      placeholder="Unit"
                      aria-label="Unit"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={8}
                sm={3}
                display="flex"
                sx={{ marginTop: { xs: 2, md: 0 } }}
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-sku" sx={{ mt: 0 }}>
                  Category
                </CustomFormLabel>
                <Tooltip title="the Stock Keeping Unit of the item">
                  <IconButton sx={{ left: '-5px' }}>
                    <IconHelp size={15} /> {/* Adjust the size as needed */}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                sx={{ height: { lg: '50px' }, alignContent: { lg: 'end' } }}
              >
                <Autocomplete
                  id="category_id"
                  noOptionsText={
                    isSearching
                      ? 'Searching...'
                      : isEmptySearch
                      ? `Can't find search word`
                      : 'Type to search'
                  }
                  value={formik.values.category_id || null}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('category_id', newValue);
                    if (newValue === null) {
                      setCategoryOptions([]);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('category_id', true)}
                  options={categoryOptions.map((p) => p.id)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return categoryOptions.find((p) => p.id === option)?.name ?? '';
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={formik.touched.category_id && Boolean(formik.errors.category_id)}
                      helperText={formik.touched.category_id && formik.errors.category_id}
                      onChange={(e: any) => autocompleteSearchChange(e, 'categories')}
                      variant="standard"
                      placeholder="Category Name"
                      aria-label="Category Name"
                    />
                  )}
                />

                {/* <CustomTextField
                  id="category_id"
                  size="small"
                  variant="standard"
                  label="Item category_id"
                  value={formik.values.category_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.category_id && Boolean(formik.errors.category_id)}
                  helperText={formik.touched.category_id && formik.errors.category_id}
                  fullWidth
                /> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} lg={5}>
            <Grid container rowSpacing={{ xs: 0, md: 1 }}>
              {/* <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="manufacturer_id"
                  size="small"
                  variant="standard"
                  label="manufacturer_id/Producer"
                  multiline
                  maxRows={6}
                  value={formik.values.manufacturer_id}
                  options={manufacturerOptions.map((option) => ({ value: option.value }))}
                  onSelect={(value: any) => formik.setFieldValue('manufacturer_id', value)}
                  onSearch={manufacturerChange}
                  fullWidth
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={3} display="flex" alignItems="center">
                <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0, }}>
                  Part No. Supplier
                </CustomFormLabel>
                <Tooltip title="Part_No_Supplier">
                  <IconButton sx={{ left: "-5px" }}>
                    <IconHelp size={15} /> 
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="part_no_supplier"
                  size="small"
                  variant="standard"
                  label="Part Number supplier"
                  value={formik.values.part_no_supplier}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.part_no_supplier && Boolean(formik.errors.part_no_supplier)}
                  helperText={formik.touched.part_no_supplier && formik.errors.part_no_supplier}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ marginTop: { xs: 2, md: 0 } }} display="flex" alignItems="center">
                <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0, }}>
                  Part No. RKC
                </CustomFormLabel>
                <Tooltip title="Part_No_rkc">
                  <IconButton sx={{ left: "-5px" }}>
                    <IconHelp size={15} /> 
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="part_no_rkc"
                  size="small"
                  label="Part number"
                  variant="standard"
                  value={formik.values.part_no_rkc}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.part_no_rkc && Boolean(formik.errors.part_no_rkc)}
                  helperText={formik.touched.part_no_rkc && formik.errors.part_no_rkc}
                  fullWidth
                />
              </Grid> */}

              <Grid
                item
                xs={8}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0 }}>
                  Description
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="description"
                  size="small"
                  variant="standard"
                  label="Description of item"
                  multiline
                  maxRows={6}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  fullWidth
                />
              </Grid>

              <Grid container mt={3}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{ marginTop: { xs: 2, md: 0 } }}
                  display="flex"
                  alignItems="center"
                >
                  <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0, mb: { xs: '-1px', sm: 0 } }}>
                    Dimension
                  </CustomFormLabel>
                  <Tooltip title="A measure of size, e.g., length, width, or height, expressed in centimeters or inches">
                    <IconButton sx={{ left: '-5px' }}>
                      <IconHelp size={15} /> {/* Adjust the size as needed */}
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TextField
                      name="length"
                      placeholder="Length"
                      variant="standard"
                      size="small"
                      value={formik.values.length}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.length && Boolean(formik.errors.length)}
                      helperText={formik.touched.length && formik.errors.length}
                      sx={{ width: '90px', mx: 0.5 }}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'center' },
                      }}
                    />
                    <Typography sx={{ mx: 0.5 }}>X</Typography>
                    <TextField
                      name="width"
                      placeholder="Width"
                      variant="standard"
                      size="small"
                      value={formik.values.width}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.width && Boolean(formik.errors.width)}
                      helperText={formik.touched.width && formik.errors.width}
                      sx={{ width: '90px', mx: 0.5 }}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'center' },
                      }}
                    />
                    <Typography sx={{ mx: 0.5 }}>X</Typography>
                    <TextField
                      name="height"
                      placeholder="Height"
                      variant="standard"
                      size="small"
                      value={formik.values.height}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.height && Boolean(formik.errors.height)}
                      helperText={formik.touched.height && formik.errors.height}
                      sx={{ width: '90px', mx: 0.5 }}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'center' },
                      }}
                    />
                    <Button
                      id="unit-selector"
                      aria-controls="unit-menu"
                      aria-haspopup="true"
                      onClick={handleButtonClick}
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: 1, height: '25px', left: '20px' }}
                    >
                      {dimensions.unit} <IconChevronDown />
                    </Button>
                    <Menu
                      id="unit-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleMenuItemClick('cm')}>cm</MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick('in')}>in</MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                item
                xs={8}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0 }}>
                  Lead Time
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <CustomTextField
                  id="lead_time"
                  size="small"
                  variant="standard"
                  label="Lead Time"
                  value={formik.values.lead_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lead_time && Boolean(formik.errors.lead_time)}
                  helperText={formik.touched.lead_time && formik.errors.lead_time}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={8}
                sm={3}
                sx={{ marginTop: { xs: 2, md: 0 } }}
                display="flex"
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0 }}>
                  Min. Qty
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="minimum_stock"
                  size="small"
                  variant="standard"
                  placeholder="Enter minimum quantity"
                  value={formik.values.minimum_stock}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.minimum_stock && Boolean(formik.errors.minimum_stock)}
                  helperText={formik.touched.minimum_stock && formik.errors.minimum_stock}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sx={{ marginTop: { xs: 2, md: 0 } }}>
                <Grid container>
                  {/* <Grid item sm={12} lg={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={is_returnable}
                          color="primary"
                          onChange={handleReturnableChange}
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                        />
                      }
                      label="Is Returnable Item?"
                    />
                  </Grid> */}

                  <Grid item sm={12} lg={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={formik.values.has_serial == 1 ? true : false}
                          color="primary"
                          onChange={handleHasSerialChange}
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                        />
                      }
                      label="Has Serial Number?"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*----- IMAGE -----*/}
          <Grid item sm={12} lg={2} flexGrow={1} sx={{ marginTop: { xs: 2, md: 0 } }}>
            <Box
              sx={{
                borderRadius: '10px',
                backgroundColor: '#f1f2f2',
                border: '2px dotted lightgrey',
              }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} textAlign="center">
                  {!selectedFile && (
                    <>
                      <Box
                        sx={{
                          backgroundColor: '#f1f2f2',
                          padding: '20px', // Optional: Add some padding if desired
                          borderRadius: '10px', // Optional: Add border radius for rounded corners
                        }}
                      >
                        <p
                          style={{
                            marginTop: '90px',
                            marginBottom: '20px',
                            color: '#ea6d6e',
                            fontWeight: 'bold',
                          }}
                        >
                          No image uploaded
                        </p>
                        <Grid container justifyContent="center" marginBottom={10}>
                          <label htmlFor="image-upload">
                            <Button variant="contained" component="span">
                              Upload Image
                            </Button>
                          </label>
                          <div>
                            <Input
                              type="file"
                              onChange={handleImageUpload}
                              sx={{ display: 'none' }}
                              inputProps={{ accept: 'image/*' }}
                              id="image-upload"
                            />
                          </div>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {selectedFile ? (
                    <>
                      <Grid>
                        <Box
                          sx={{
                            backgroundColor: '#f1f2f2',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.01)',
                            padding: '20px',
                            borderRadius: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <div style={{ flexDirection: 'column', padding: '10px' }}>
                            <img
                              src={URL.createObjectURL(selectedFile)}
                              alt="Uploaded"
                              style={{ maxWidth: '160px', maxHeight: '160px' }}
                            />
                            <div style={{ paddingTop: '10px' }}>
                              <Button variant="contained" color="error" onClick={handleDeleteImage}>
                                Delete Image
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/*---------- end of first row ----------*/}
        {/*--------------------*/}

        {/*SEPARATOR LINE*/}
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ mx: '-20px' }} />
          </Grid>
        </Grid>
        {/*SEPARATOR LINE*/}

        {/*---------- second row ----------*/}
        {/*--------------------*/}
        <Grid container padding={3} columnSpacing={2}>
          {/* <Grid item sm={12} lg={5}>
            <Grid container rowSpacing={{ xs: 0, md: 1 }}>
              <Grid item xs={12} sm={3} display="flex" alignItems="center">
                <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0, }}>
                  Supplier
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={9} sx={{ height: { lg: "50px" }, alignContent: { lg: "end" } }}>

                <Autocomplete
                  id="supplier_id"
                  noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                  value={formik.values.supplier_id || null}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('supplier_id', newValue);
                    if (newValue === null) {
                      setSupplierOptions([]);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('supplier_id', true)}
                  options={supplierOptions.map(p => p.id)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return supplierOptions.find(p => p.id === option)?.name ?? '';
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={formik.touched.supplier_id && Boolean(formik.errors.supplier_id)}
                      helperText={formik.touched.supplier_id && formik.errors.supplier_id}
                      onChange={(e: any) => autocompleteSearchChange(e, 'suppliers')}
                      variant="standard"
                      placeholder="Supplier Name"
                      aria-label="Supplier Name"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={8}
                sm={3}
                display="flex"
                sx={{ marginTop: { xs: 2, md: 0 } }}
                alignItems="center"
              >
                <CustomFormLabel htmlFor="fs-des" sx={{ mt: 0 }}>
                  Location
                </CustomFormLabel>
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                sx={{ height: { lg: '50px' }, alignContent: { lg: 'end' } }}
              >
                <Grid container columnSpacing={2}>
                  <Grid item xs={9}>
                    <Autocomplete
                      id="location"
                      noOptionsText={
                        isSearching
                          ? 'Searching...'
                          : isEmptySearch
                          ? `Can't find search word`
                          : 'Type to search'
                      }
                      value={formik.values.location || null}
                      onChange={(_, newValue) => {
                        formik.setFieldValue('location', newValue);
                        if (newValue === null) {
                          setLocationOptions([]);
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('location', true)}
                      options={locationOptions.map((p) => p.id)}
                      fullWidth
                      getOptionLabel={(option) => {
                        return locationOptions.find((p) => p.id === option)?.name ?? '';
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          error={formik.touched.location && Boolean(formik.errors.location)}
                          helperText={formik.touched.location && formik.errors.location}
                          onChange={(e: any) => autocompleteSearchChange(e, 'locations')}
                          variant="standard"
                          placeholder="Location Name"
                          aria-label="Location Name"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      id="floor"
                      value={formik.values.floor || null}
                      onChange={(_, newValue) => {
                        console.log(newValue);
                        formik.setFieldValue('floor', newValue);
                      }}
                      onBlur={() => formik.setFieldTouched('floor', true)}
                      options={['Floor 1', 'Floor 2', 'Floor 3', 'Floor 4', 'Floor 5']}
                      fullWidth
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          error={formik.touched.floor && Boolean(formik.errors.floor)}
                          helperText={formik.touched.floor && formik.errors.floor}
                          variant="standard"
                          placeholder="Floor"
                          aria-label="Floor"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item sm={12} lg={5}>
            <Grid container rowSpacing={{ xs: 0, md: 1 }}>
              <Grid item xs={12} sm={3} display="flex" alignItems="center">
                <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0 }}>
                  Buying Price
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="buying_price"
                  size="small"
                  variant="standard"
                  label="Buying Price"
                  value={formik.values.buying_price}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    const reqBuying = parseFloat(e.target.value);
                    formik.setFieldValue('selling_price', reqBuying + reqBuying * 0.1);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.buying_price && Boolean(formik.errors.buying_price)}
                  helperText={formik.touched.buying_price && formik.errors.buying_price}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: 'buying_price',
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={3} display="flex" alignItems="center">
                <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0 }}>
                  Selling Price
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  id="selling_price"
                  size="small"
                  variant="standard"
                  label="Selling Price"
                  multiline
                  disabled
                  maxRows={6}
                  value={formik.values.selling_price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.selling_price && Boolean(formik.errors.selling_price)}
                  helperText={formik.touched.selling_price && formik.errors.selling_price}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: 'selling_price',
                    },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            padding={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Stack direction="row" spacing={2}>
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </LoadingButton>
              <Button variant="text" color="error" to="/product-master" component={Link}>
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {/*---------- end of second row ----------*/}
        {/*--------------------*/}
      </form>
    </div>
  );
};

export default FormSeparator;
