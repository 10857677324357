// IssuanceEntryTransactionsTable.tsx
import React, { useEffect, useMemo, useState, useCallback, useImperativeHandle } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box, Chip } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconDeviceDesktop, IconEdit, IconEye, IconPlus, IconTrash } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

export type IssuanceEntryTransactionsApiResponse = {
  data: Array<IssuanceEntryTransactions>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

export type IssuanceEntryTransactions = {
  id: number;
  reference_number?: string;
  slip_number?: string;
  date_of_issuance?: string;
  transactions?: any;
  name: string;
  description: string;
  customer: any;
  status: string;
};

type IssuanceEntryTransactionsTableProps = {
  onOpenFloatingIssuance: (transaction: IssuanceEntryTransactions) => void;
  onRefetchRef: React.RefObject<{ refetch: () => void }>;
  userlevel: any;
};

const IssuanceEntryTransactionsTable: React.FC<IssuanceEntryTransactionsTableProps> = ({
  onOpenFloatingIssuance,
  onRefetchRef,
  userlevel,
}) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IssuanceEntryTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/ie_transactions`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as IssuanceEntryTransactionsApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching offices:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<IssuanceEntryTransactions>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference Number',
        size: 180,
      },
      {
        accessorKey: 'slip_number',
        header: 'Slip Number',
        size: 180,
      },
      {
        accessorKey: 'customer',
        header: 'Customer',
        size: 180,
        Cell: ({ row }) => row.original.customer.name,
      },
      {
        accessorKey: 'date_of_issuance',
        header: 'Transaction Date',
        size: 180,
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue<string>());
          return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 180,
        Cell: ({ row }) =>
          row.original.status === 'posted' || row.original.status === 'checked' ? (
            <Chip label={row.original.status} color="success" />
          ) : row.original.status === 'floating' ? (
            <Chip label={row.original.status} color="warning" />
          ) : (
            <Chip label={row.original.status} color="error" />
          ),
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const { original } = row;
          const { status } = original;

          const renderButton = () => {
            if (status === 'posted') {
              return (
                <Button
                  onClick={() => handleViewIssuanceEntryTransaction(original)}
                  variant="outlined"
                >
                  <IconEye />
                </Button>
              );
            }

            if (status === 'floating' || status === 'checked' || status === 'finalized') {
              if (userlevel === 'stockman' || userlevel === 'checker' || userlevel === 'customer') {
                return (
                  <Button
                    onClick={() => handleViewIssuanceEntryTransaction(original)}
                    variant="outlined"
                  >
                    <IconEye />
                  </Button>
                );
              }
              return (
                <Button onClick={() => onOpenFloatingIssuance(original)} variant="outlined">
                  <IconDeviceDesktop />
                </Button>
              );
            }

            return null;
          };

          return (
            <Grid container spacing={2}>
              <Grid item>{renderButton()}</Grid>
            </Grid>
          );
        },
      },
    ],
    [onOpenFloatingIssuance],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewIssuanceEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/issuance-entry-view-transaction');
  };

  const handleOpenTest = (row: any) => {
    console.log('Open Test', row);
  };

  useImperativeHandle(onRefetchRef, () => ({
    refetch: () => {
      if (refetch) {
        refetch();
      }
    },
  }));

  // React.useImperativeHandle(onRefetchRef, () => ({
  //   refetch,
  // }));

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const IssuanceEntryTransactionsView: React.FC<IssuanceEntryTransactionsTableProps> = ({
  onOpenFloatingIssuance,
  onRefetchRef,
  userlevel,
}) => (
  <QueryClientProvider client={queryClient}>
    <IssuanceEntryTransactionsTable
      onOpenFloatingIssuance={onOpenFloatingIssuance}
      onRefetchRef={onRefetchRef}
      userlevel={userlevel}
    />
  </QueryClientProvider>
);

export default IssuanceEntryTransactionsView;
