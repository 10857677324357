import React, { useMemo, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  IconButton,
  Tooltip,
  FormControlLabel,
  Typography,
  Avatar,
  TextField,
  InputAdornment,
  Paper,
  Button,
  Grid,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useSelector, useDispatch } from 'src/store/Store';
import { fetchProducts } from 'src/store/apps/eCommerce/ECommerceSlice';
import { Link } from 'react-router-dom';
import { IconDotsVertical, IconEdit, IconFilter, IconSearch, IconTrash } from '@tabler/icons';
import { ProductType } from 'src/views/product-master/eCommerce';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import CustomSwitch from 'src/components/theme-elements/CustomSwitch';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { isError } from 'lodash';
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { ArrowForward, RefreshOutlined } from '@mui/icons-material';
import { adjustAndFormat, formatCurrency } from 'src/globals/NumericFormat';

type Adjustment = {
  id: number;
  product_id: number;
  product_name: string;
  sku: string;
  is_price_adjustment: number;
  is_quantity_adjustment: number;
  buying_price: number;
  selling_price: number;
  quantity: number;
  requested_selling: number;
  requested_buying: number;
  requested_quantity: number;
  status: any;
  reason: string;
};

type AdjustmentApiResponse = {
  data: Array<Adjustment>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

const statusSelect = [{ label: 'Approved', value: '1' }, { label: 'Pending', value: '2' }];
const AdjustmentTableList = () => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<AdjustmentApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/adjustments`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as AdjustmentApiResponse;

        console.log(json);

        return json;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Adjustment>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        minSize: 10,
        maxSize: 10,
        size: 50,
      },
      {
        accessorKey: 'product_name',
        header: 'Product Name',
        size: 180,
      },
      {
        accessorKey: 'sku',
        header: 'SKU no.',
        size: 180,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: statusSelect,
        size: 20,
        Cell: ({ row }) => {
          const status = row.original.status;
          const statusText = status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

          return (
            <div>
              {statusText}
            </div>
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date requested',
        size: 10,
      },

      // {
      //   accessorKey: 'actions',
      //   header: 'Actions',
      //   size: 120,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   Cell: ({ row }) => (
      //     <Grid container spacing={2}>
      //       <Grid item>
      //         <Button onClick={() => console.log("Edit clicked")} variant="outlined">
      //           <IconEdit />
      //         </Button>
      //       </Grid>
      //       <Grid item>
      //         <Button onClick={() => console.log("Delete clicked")} variant="outlined">
      //           <IconTrash />
      //         </Button>
      //       </Grid>
      //     </Grid>
      //   ),
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand'],
      },
    },
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      })
    }),
    renderDetailPanel: ({ row }) => {
      const status = row.original.status;
      const statusText = status == 1 ? "Price" : status == 2 ? "Quantity" : "Both";
      const baseQuant = row.original.quantity ? row.original.quantity : 0;
      const reqQuant = row.original.requested_quantity ? row.original.requested_quantity : 0;

      const baseQuantity = typeof baseQuant === 'string' ? parseFloat(baseQuant) : baseQuant;
      const quantityAdjustment = typeof reqQuant === 'string' ? parseFloat(reqQuant) : reqQuant;

      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'sticky',
            width: '100%',
          }}
        >
          <Grid container>
            <Grid item xs={2} sx={{ alignItems: 'center' }} gap={1}>
              <Typography variant='h5'>Request Type: </Typography>
              <Typography variant='subtitle1'>{statusText} Adjustment </Typography>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: 'center' }}>
              <Typography variant='h5'>Requested Buying Price Change</Typography>
              <Typography variant="body2">
                Current: {formatCurrency(row.original.buying_price)}
              </Typography>
              <Typography variant="body2">
                Adjustment: {formatCurrency(row.original.requested_buying)}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                New Price: {adjustAndFormat(row.original.buying_price, row.original.requested_buying)}
              </Typography>

            </Grid>
            <Grid item xs={3} sx={{ alignItems: 'center' }}>
              <Typography variant='h5'>Requested Buying Price Change</Typography>
              <Typography variant="body2">
                Current: {formatCurrency(row.original.selling_price)}
              </Typography>
              <Typography variant="body2">
                Adjustment: {formatCurrency(row.original.requested_selling)}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                New Price: {adjustAndFormat(row.original.selling_price, row.original.requested_selling)}
              </Typography>

            </Grid>
            <Grid item xs={3} sx={{ alignItems: 'center' }}>
              <Typography variant='h5'>Requested Quantity Change: </Typography>
              <Typography variant="body2">
                Current: {row.original.quantity}
              </Typography>
              <Typography variant="body2">
                Adjustment: {row.original.requested_quantity ? row.original.requested_quantity : 0}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                New Quantity: {baseQuantity + quantityAdjustment}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const AdjustmentPage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AdjustmentTableList />
    </QueryClientProvider>
  );
};

export default AdjustmentPage;
