import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  Stack,
  Divider,
  Snackbar,
  AlertTitle,
  Alert,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useAuth } from 'src/contexts/AuthProvider';
import { ReactComponent as LOGO } from 'src/assets/images/svgs/rkc_wms_logo.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface loginType {
  title?: string;
  subtitle?: JSX.Element | JSX.Element[];
  subtext?: JSX.Element | JSX.Element[];
}

const validationSchema = yup.object({
  username: yup.string().required('Username or username is required'),
  password: yup.string().required('Password is required'),
});

let snackbarCalled = false;
const AuthLogin = ({ title, subtitle, subtext }: loginType) => {
  const [failedAttempts, setFailedAttempts] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { login } = useAuth();

  let snackbarConfig: {
    open: boolean;
    title: string;
    message: string;
    severity: 'success' | 'error';
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const authResponse = await login({ username: values.username, password: values.password });
        if (authResponse) {
          if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('username', values.username);
            localStorage.setItem('password', values.password);
          } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('username');
            localStorage.removeItem('password');
          }

          // Login successful
          snackbarConfig = {
            open: true,
            title: 'Success',
            message: 'Login successful',
            severity: 'success',
          };

          setFailedAttempts(0);
        } else {
          // Login failed
          setFailedAttempts((prev) => prev + 1);
          snackbarConfig = {
            open: true,
            title: 'Error',
            message: 'Login failed. Username or Password incorrect',
            severity: 'error',
          };
        }
      } catch (error) {
        console.error('Error during login:', error);
        snackbarConfig = {
          open: true,
          title: 'Error',
          message: 'An error occurred during login',
          severity: 'error',
        };
      } finally {
        if (isButtonDisabled) {
          snackbarConfig = {
            open: true,
            title: 'Error',
            message: 'Login failed. Try again later',
            severity: 'error',
          };
        }
        setSnackbarData(snackbarConfig);
      }
    },
  });

  const [snackbarData, setSnackbarData] = useState<{
    open: boolean;
    title: string;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    title: '',
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  snackbarConfig = {
    open: true,
    title: 'Error',
    message: 'Login failed. Try again later',
    severity: 'error',
  };

  if (isButtonDisabled) {
    snackbarConfig = {
      open: true,
      title: 'Error',
      message: 'Login failed. Try again later',
      severity: 'error',
    };

    if (!snackbarCalled) {
      setSnackbarData(snackbarConfig);
      snackbarCalled = true; // Set flag to true to indicate that snackbarData has been set
    }
  }

  useEffect(() => {
    // Check if there are saved credentials
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    if (savedRememberMe && savedUsername && savedPassword) {
      formik.setValues({ username: savedUsername, password: savedPassword });
      setRememberMe(true);
    }

    // Check if there is a stored timestamp in localStorage
    const storedTimestamp = localStorage.getItem('failedAttemptTimestamp');
    if (storedTimestamp) {
      const storedTime = parseInt(storedTimestamp, 10);
      const currentTime = Date.now();
      const timeDifference = currentTime - storedTime;
      const remainingTime = Math.max(60 - Math.floor(timeDifference / 1000), 0);

      // Update failedAttempts and countdown
      setFailedAttempts(3);
      setCountdown(remainingTime);

      // Start countdown
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            setIsButtonDisabled(false); // Enable button after countdown
            localStorage.removeItem('failedAttemptTimestamp'); // Clear localStorage
            clearInterval(intervalId); // Clear interval when countdown reaches 0
          }

          return prevCountdown ? prevCountdown - 1 : prevCountdown;
        });
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup interval
    }
  }, []);

  useEffect(() => {
    // Store timestamp in localStorage when failedAttempts reaches 3
    if (failedAttempts >= 3) {
      localStorage.setItem('failedAttemptTimestamp', Date.now().toString());
      setIsButtonDisabled(true);
      setCountdown(60); // Set countdown duration in seconds
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            setIsButtonDisabled(false); // Enable button after countdown
            localStorage.removeItem('failedAttemptTimestamp'); // Clear localStorage
            clearInterval(intervalId); // Clear interval when countdown reaches 0
          }

          return prevCountdown ? prevCountdown - 1 : prevCountdown;
        });
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup interval
    }
  }, [failedAttempts]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      {title ? (
        <Typography fontWeight="700" variant="h3" mb={1}>
          {title}
        </Typography>
      ) : null}

      {subtext}

      <Box display="flex" alignItems="center" justifyContent="center">
        <LOGO />
      </Box>

      <Typography
        fontWeight="700"
        fontSize={13}
        variant="subtitle2"
        mb={1}
        sx={{ textAlign: 'center' }}
      >
        {/* Precision in Every Pulse */}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <Box>
            <CustomFormLabel htmlFor="username">Username</CustomFormLabel>
            <CustomTextField
              id="username"
              variant="outlined"
              fullWidth
              value={formik.values.username}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box>
            <CustomFormLabel htmlFor="password">Password</CustomFormLabel>
            <CustomTextField
              id="password"
              variant="outlined"
              fullWidth
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Stack justifyContent="space-between" direction="row" alignItems="center" my={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                }
                label="Remember Me"
              />
            </FormGroup>
            <Typography
              component={Link}
              to="/auth/forgot-password"
              fontWeight="500"
              sx={{
                textDecoration: 'none',
                color: 'primary.main',
              }}
            >
              Forgot Password ?
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            disabled={isButtonDisabled}
          >
            Sign In
          </Button>

          {isButtonDisabled && (
            <Typography variant="body2" color="textSecondary" align="center">
              Please wait {countdown} seconds before trying again
            </Typography>
          )}
        </Box>
      </form>
      {subtitle}

      <React.Fragment>
        <Snackbar
          open={snackbarData.open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={12000}
          onClose={handleCloseSnackbar}
          TransitionComponent={Slide}
        >
          <Alert
            severity={snackbarData.severity}
            variant="filled"
            sx={{ width: '100%', color: 'white' }}
          >
            <AlertTitle>{snackbarData.title}</AlertTitle>
            {snackbarData.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </>
  );
};

export default AuthLogin;
